// client/src/pages/PostPage.js (or .tsx)
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import { client } from '../../sanity/client'; // Corrected path
import Navbar from '../../components/navbar/navbar'; // Corrected path
import Footer from '../../components/footer/footer'; // Corrected path

const { projectId, dataset } = client.config();
const urlFor = (source) =>
  projectId && dataset
    ? imageUrlBuilder({ projectId, dataset }).image(source)
    : null;

const POST_QUERY = `*[_type == "post" && slug.current == $slug][0]`;

function PostPage() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    client.fetch(POST_QUERY, { slug })
      .then((data) => {
        if (!data) {
          setError('Post not found');
          return;
        }
        setPost(data);
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to load post');
      });
  }, [slug]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!post) {
    return <div>Loading...</div>; // Or a proper loading indicator
  }

  const postImageUrl = post.image
    ? urlFor(post.image).width(550).height(310).url()
    : null;

  return (
    <>
    <Navbar/>
    <main className="container mx-auto min-h-screen max-w-3xl p-8 flex flex-col gap-4">
      <Link to="/" className="hover:underline">
        ← Back to posts
      </Link>
      {postImageUrl && (
        <img
          src={postImageUrl}
          alt={post.title}
          className="aspect-video rounded-xl"
          width="550"
          height="310"
        />
      )}
      <h1 className="text-4xl font-bold mb-8">{post.title}</h1>
      <div className="prose">
        <p>Published: {new Date(post.publishedAt).toLocaleDateString()}</p>
        {Array.isArray(post.body) && <PortableText value={post.body} />}
      </div>
    </main>
    <Footer/>
    </>
  );
}

export default PostPage;
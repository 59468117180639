import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/navbar'
import AddressBar from '../../components/address_bar/address_bar';
import "./technologySolutions.css";
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
// import Footer from '../../components/footer/footer';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { servicesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';
// icons
import { FaPaintBrush, FaCartPlus, FaMobileAlt, FaUsers, FaCode } from "react-icons/fa";


function TechnologySolutions() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(servicesSchemas.technologySolutions);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>

      <SeoHelmet 
        title="Technology Solutions - StatixPro"
        description="Leverage cutting-edge technology solutions from StatixPro. We provide custom software development, AI integration, and digital transformation services."
        keywords="technology solutions, software development, AI integration, digital transformation, tech consulting, custom software, IT services"
        canonicalUrl="https://statix.pro/technology-solutions"
        ogImage="https://statix.pro/technology-solutions-og.jpg"
        ogType="website"
      />
<AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
          <h1 className='servicebs_topic'><span className="highlight">Technology Solutions</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
          <div className="hero-buttons mt-4">
            <a href="https://forms.gle/nkMm9CfMs22ZBQ888" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">Get a Free Consultancy</button></a>
            {/* <button className="btn btn-secondary contact-button">Contact us</button> */}
          </div>
        </div>
      </section>

      {/* Technology expertise solutions starts here */}
      <section className="tech-solutions-section">
      <div className="container text-center">
        <h2>
          Empower Your Business with <span className="highlight">Leading-Edge Technologies</span>
        </h2>
        <p>
          Here at Statix.pro, innovation meets functionality as we help implement technology solutions
          to upgrade your digital infrastructure. By leveraging new technology advances, we design and
          build intuitive, scalable, tailored-to-your-business-need platforms.
        </p>

        <div className="row mt-5">
          {/* Card 1 */}
          <div className="col-md-4 mb-4">
            <div className="tech-card tech-card-blue">
              <h5><FaPaintBrush className="icon" /> UI/UX Development:</h5>
              <p>
                Transform ideas into thoroughly workable, user-friendly designs that improve customer
                experiences by keeping them engaged.
              </p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-4 mb-4">
            <div className="tech-card tech-card-peach">
              <h5><FaCartPlus className="icon" /> E-commerce Development:</h5>
              <p>
                Developing dynamic, AI-driven solutions for e-commerce to increase sales and make
                operations smooth.
              </p>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-4 mb-4">
            <div className="tech-card tech-card-blue">
              <h5><FaMobileAlt className="icon" /> Mobile Application Development:</h5>
              <p>
                Bringing ideas into reality with innovative mobile apps, ensuring great performance
                across devices.
              </p>
            </div>
          </div>

          {/* Card 4 */}
          <div className="col-md-4 mb-4">
            <div className="tech-card tech-card-peach">
              <h5><FaUsers className="icon" /> CRM Development:</h5>
              <p>
                Enhance your customer relationship with bespoke, AI-driven solutions that ramp up the
                level of efficiency and engagement.
              </p>
            </div>
          </div>

          {/* Card 5 */}
          <div className="col-md-4 mb-4">
            <div className="tech-card tech-card-blue">
              <h5><FaCode className="icon" /> Web Application Development:</h5>
              <p>
                Develop robust and scalable web applications to sort out critical business challenges.
              </p>
            </div>
          </div>
        </div>

        <button className="btn btn-primary mt-4">Transform Your Business Today</button>
      </div>
    </section>

      {/* technology expertise solutions ends here */}

          {/* CTA starts here */}
    <section className="brand-section d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="section-title text-light">Looking to Elevate Your Digital Ecosystem?
        </h2>
        <p className="section-description">
        It is the place where technology and expression finally unite into unrivalled solutions for growth and 
innovation at Statix.pro.
        </p>
        <button className="btn section-button">
        Let’s Connect
        </button>
        <p className="section-footer">
        Let’s build the future together. Let's make a call today to explore how our technology solutions can transform your business.
        </p>
      </div>
    </section>
    {/* CTA ends here */}


            <Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default TechnologySolutions
import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/navbar'
import AddressBar from '../../components/address_bar/address_bar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { policiesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

function Pricing() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(policiesSchemas.pricing);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);


  return (
    <div>
       <SeoHelmet 
        title="Pricing & Plans - StatixPro Digital Marketing Services"
        description="Explore StatixPro's transparent pricing and flexible plans for digital marketing services. Find the perfect package for your business needs and budget."
        keywords="digital marketing pricing, service packages, marketing plans, pricing plans, service costs, marketing packages, affordable marketing"
        canonicalUrl="https://statix.pro/pricing"
        ogImage="https://statix.pro/pricing-og.jpg"
        ogType="website"
      />
        <AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
          <h1 className='servicebs_topic'><span className="highlight">Pricing Terms</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
        </div>
      </section>

{/* section 2 starts here */}
<div className="container my-5">
      <div className="policy-header text-center mb-4">
        <p className="text-muted">
          We understand that plans can change. Here’s how we handle cancellations.
        </p>
      </div>

      <div className="shadow-sm" style={{textAlign:"left"}}>
        <div className="card-body">
          <h2 className="card-title text-primary">What You Need to Know</h2>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong>Cancellation Requests:</strong> You can cancel your subscription or service by contacting our support team at least 48 hours in advance.
            </li>
            <li className="list-group-item">
              <strong>Refunds:</strong> Refund eligibility depends on the specific terms of your service package. If applicable, refunds will be processed within 7 business days.
            </li>
            <li className="list-group-item">
              <strong>Non-refundable Fees:</strong> Certain fees, such as setup costs, may not be refundable.
            </li>
            <li className="list-group-item">
              <strong>Service Interruptions:</strong> We reserve the right to cancel services in case of a breach of our terms or for any unforeseen circumstances beyond our control.
            </li>
          </ul>
        </div>
      </div>
    </div>
{/* section 2 ends here */}
      
      <Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>

    </div>
  )
}

export default Pricing
import React, { useEffect } from 'react'
import "./aboutus.css"
import AddressBar from '../../components/address_bar/address_bar';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
// import Footer from '../../components/footer/footer';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import { aboutPageSchema } from '../../components/SEO/SeoSchemas';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import ReactGA from 'react-ga4';

// icon images
import Collabration from "../../components/assets/about_icons/Collaboration.png"
import Creativity from "../../components/assets/about_icons/Creativity.png"
import Excellence from "../../components/assets/about_icons/Excellence.png"
import Innovation from "../../components/assets/about_icons/Innovation.png"
import Integrity from "../../components/assets/about_icons/Integrity.png"

function Aboutus() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(aboutPageSchema);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
       <SeoHelmet 
          title="About StatixPro - Our Mission, Values & Team"
          description="Learn about StatixPro's journey, our mission to transform businesses through AI-powered digital marketing, and meet the team behind our success."
          keywords="about StatixPro, digital marketing company, AI marketing team, company values, digital transformation experts"
          canonicalUrl="https://statix.pro/aboutus"
          // ogImage="https://statix.pro/about-team.jpg"
          ogType="website"
        />
        <AddressBar/>

        {/* Home page */}
      <section className="about-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="about-hero-content text-center">
          <h1 className='about_topic'><span className="highlight">Innovation</span> is seeing what everybody has seen and <span className="highlight">thinking what nobody has thought.</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p>
          <div className="hero-buttons mt-4">
            <a href="https://heyzine.com/flip-book/6d0719c6ba.html" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">View Brochure</button></a>
            <a href="https://forms.gle/4Ki54AeKH5VqHwjz7" target="_blank" rel="noopener noreferrer"><button className="btn btn-secondary contact-button">Get Free Consultancy</button></a>
        </div>
        </div>
      </section>

      {/* Our story starts here */}
      <section className="our-story-section">
      <div className="story-container">
        <div className="story-content">
          <h1 className="story-heading">Turning Vision Into Reality</h1>
          <p className="story-text">
            Born from a desire to revolutionize the digital marketing landscape, 
            Statix.pro was founded to empower businesses with AI-driven strategies and 
            data-driven insights. Starting as a small but passionate team, we’ve grown into 
            a leading force in India’s digital marketing industry. From our inception, our 
            focus has been clear—delivering measurable results while making the process simple, 
            efficient, and impactful.
          </p>
        </div>
        <div className="side-title">Our Story</div>
      </div>
    </section>
      {/* Our story ends here */}


      {/* Numbers section starts here */}
      <div className="stats-container container">
      <h2 className="stats-title">A Few Numbers That Speak Volumes</h2>
      <div className="stats-card">
        <div className="stat-item">
          <h4>50+ Successful Campaigns</h4>
          <p>in diverse industries.</p>
        </div>
        <div className="stat-divider"></div>
        <div className="stat-item">
          <h4>5X Lead Generation</h4>
          <p>for our clients in just six months.</p>
        </div>
        <div className="stat-divider"></div>
        <div className="stat-item">
          <h4>100% Client Satisfaction</h4>
          <p>with measurable, impactful results.</p>
        </div>
        <div className="stat-divider"></div>
        <div className="stat-item">
          <h4>AI-Powered Tools</h4>
          <p>developed in-house for superior performance.</p>
        </div>
      </div>
    </div>
      {/* Numbers section ends here */}


    {/* Our core values starts here */}
    <section className="core-values-section py-5">
      <div className="container text-center">
        <h2 className="core-values-title mb-4">Our Core Values</h2>
        <div className="row justify-content-center">
          {/* Creativity */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-value-item">
              <img
                src={Creativity} 
                alt="Creativity Icon"
                className="core-value-icon mb-3"
              />
              <h5 className="core-value-heading">Creativity</h5>
              <p className="core-value-description">
                Thinking beyond the ordinary.
              </p>
            </div>
          </div>

          {/* Collaboration */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-value-item">
              <img
                src={Collabration} 
                alt="Collaboration Icon"
                className="core-value-icon mb-3"
              />
              <h5 className="core-value-heading">Collaboration</h5>
              <p className="core-value-description">
                Fostering teamwork and partnerships that inspire success.
              </p>
            </div>
          </div>

          {/* Centered Row for Innovation & Integrity */}
          <div className="w-100"></div> {/* Forces a new row */}
          <div className="col-md-6 col-lg-5 mb-4">
            <div className="core-value-item">
              <img
                src={Innovation} 
                alt="Innovation Icon"
                className="core-value-icon mb-3"
              />
              <h5 className="core-value-heading">Innovation</h5>
              <p className="core-value-description">
                Staying ahead with AI and data-driven insights.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 mb-4">
            <div className="core-value-item">
              <img
                src={Integrity} 
                alt="Integrity Icon"
                className="core-value-icon mb-3"
              />
              <h5 className="core-value-heading">Integrity</h5>
              <p className="core-value-description">
                Delivering honest, transparent, and impactful results.
              </p>
            </div>
          </div>

          {/* Excellence */}
          <div className="w-100"></div> {/* Forces a new row */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-value-item">
              <img
                src={Excellence} 
                alt="Excellence Icon"
                className="core-value-icon mb-3"
              />
              <h5 className="core-value-heading">Excellence</h5>
              <p className="core-value-description">
                Striving for nothing less than extraordinary outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Our core values ends here */}
    
    {/* We Make It Simple, but Significant starts here */}
    <section>
    <div className="simple-significant-container text-center">
      <h2 className="title-orange">We Make It Simple, but Significant</h2>
      <p className="description">
        At <strong>Statix.pro</strong>, we simplify the complexities of digital marketing while ensuring every campaign makes a powerful impact. 
        With <strong>creativity at the heart of everything</strong> we do, we deliver solutions that are not only innovative but also meaningful.
      </p>
      <button className="btn btn-primary custom-btn">Let’s move forward, together.</button>
    </div>
    </section>
    {/* We Make It Simple, but Significant ends here */}

    {/* starts here */}
    <section>
    <div className="comparison-table container">
      <h2 className="text-center mt-5 mb-4" style={{color:"#0e376e"}}>How Statix.pro Stands Out from the Rest</h2>
      <div className="table-wrapper">
        <table className="table table-bordered text-center gfg">
          <thead>
            <tr>
              <th className="table-header">Key Aspects</th>
              <th className="table-header">Other Companies</th>
              <th className="table-header">Statix.pro</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key-aspects">Technology</td>
              <td>Uses generic tools and third-party platforms.</td>
              <td>Powered by <strong>proprietary AI tools</strong> for precision and efficiency.</td>
            </tr>
            <tr>
              <td className="key-aspects">Customization</td>
              <td>Offers one-size-fits-all solutions.</td>
              <td>Designs tailored <strong>campaigns for unique brand goals.</strong></td>
            </tr>
            <tr>
              <td className="key-aspects">Results</td>
              <td>Delivers slow and average outcomes.</td>
              <td>Achieves <strong>5X lead generation</strong> and measurable success in just six months.</td>
            </tr>
            <tr>
              <td className="key-aspects">Client Relationship</td>
              <td>Transactional approach as a service provider.</td>
              <td><strong>Collaborative approach</strong> as a growth partner.</td>
            </tr>
            <tr>
              <td className="key-aspects">Innovation</td>
              <td>Relies on traditional methods and outdated strategies.</td>
              <td> Driven by <strong> Data-Driven AI</strong> and creative innovation.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </section>
    {/* ends here */}


    {/* call to action starts here */}
    <section className="cta-section d-flex align-items-center">
      <div className="container text-center">
        <h2 className="cta-heading">
          Let’s work together to bring your <span className="highlight">brand to life.</span>
        </h2>
        <button className="cta-button">Could Shake Hands?</button>
      </div>
    </section>
    {/* call to action ends here */}

{/* footer starts here */}
<Footer/>
{/* footer ends here */}
<Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default Aboutus
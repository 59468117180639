import React, { useState, useEffect } from 'react';
import './portfolio.css';
import Navbar from '../../components/navbar/navbar';
import AddressBar from '../../components/address_bar/address_bar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { portfolioPageSchema } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

// Images
import Logodesign from "../../components/assets/Brand Design images/Logo-Designing.png"
import BrandIdentity from "../../components/assets/Brand Design images/Brand-Identity-Design.png"
import PackageDesign from "../../components/assets/Brand Design images/Package-Designing.png"
import BrandGuidelines from "../../components/assets/Brand Design images/Brand-Guidelines Designing.png"
import MarketCollateral from "../../components/assets/Brand Design images/Market-Collateral-design.png"
import SocialBranding from "../../components/assets/Brand Design images/Social-Media-Branding.png"
import MarketingAd from "../../components/assets/Brand Design images/Marketing-Ad-Design.png"
import InfographicDesign from "../../components/assets/Brand Design images/Infographic-Design.png"
import BrochureDesign from "../../components/assets/Brand Design images/Brouchure Design.png"
import PersonalBranding from "../../components/assets/Brand Design images/Personal-Branding.png"
import BannerDesign from "../../components/assets/Brand Design images/Banner-Design.png"

function Portfolio() {
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to close the modal
  const closeModal = () => setSelectedImage(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(portfolioPageSchema);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Image data array
const images = [
  { src: Logodesign, title: 'Logo Designing' },
  { src: BrandIdentity, title: 'Brand Identity Designs' },
  { src: PackageDesign, title: 'Package Designing' },
  { src: BrandGuidelines, title: 'Brand Guidelines Designing' },
  { src: MarketCollateral, title: 'Market Collateral Designs' },
  { src: SocialBranding, title: 'Social Media Branding' },
  { src: MarketingAd, title: 'Marketing Ad Designs' },
  { src: InfographicDesign, title: 'Infographic Designs' },
  { src: BrochureDesign, title: 'Brochure Designing' },
  { src: PersonalBranding, title: 'Personal Brand Designs' },
  { src: BannerDesign, title: 'Banner Designs' },
];

  return (
    <div>
      <SeoHelmet 
        title="Portfolio - StatixPro's Success Stories & Case Studies"
        description="Explore StatixPro's portfolio of successful digital marketing campaigns and brand transformations. See how we've helped businesses achieve remarkable growth."
        keywords="digital marketing portfolio, case studies, success stories, client projects, marketing results, brand transformations"
        canonicalUrl="https://statix.pro/portfolio"
        ogImage="https://statix.pro/portfolio-og.jpg"
        ogType="website"
      />
      <AddressBar />
      <section className="servicebs-hero">
        <div className="logo-and-nav">
          <Navbar />
        </div>
        <div className="servicebs-hero-content text-center">
          <h1 className="servicebs_topic">
            Here <span className="highlight">Creativity Meets Results</span>: A Showcase
          </h1>
        </div>
      </section>

      <div className="container my-5">
        <h2
          className="text-center section-title mb-4"
          style={{ paddingTop: '40px' }}
        >
          We Dare to Be Different. Your Brand, Our Honesty. A New Era of Transparency.
        </h2>
        <p
          style={{
            paddingBottom: '60px',
            fontSize: '20px',
          }}
        >
          Remember, in the world, creativity without impact is just noise—and we’re here to
          create a symphony.
        </p>
        <div className="row">
          {images.map((image, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="card shadow" onClick={() => setSelectedImage(image.src)}>
                <img
                  src={image.src}
                  alt={image.title}
                  className="card-img-top"
                  style={{ cursor: 'pointer' }}
                />
                <div className="card-body text-center">
                  <h5 className="card-title">{image.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
      <Whatsappbutton />
      <Backtotopbtn />
      <Chatsection />

      {/* Modal for image zoom */}
      {selectedImage && (
        <div className="modal show d-block" onClick={closeModal}>
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  src={selectedImage}
                  alt="Zoomed view"
                  className="img-fluid"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModal}
                style={{
                  position: 'absolute',
                  top: '6px',
                  right: '5px',
                  backgroundColor: 'white',
                  width: '30px',
                  height:'30px',
                  borderRadius: '50%',
                }}
              ></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Portfolio;

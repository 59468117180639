import React, { useEffect } from 'react';
import "./dataAnalytics.css";
import AddressBar from '../../components/address_bar/address_bar';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { FaChartPie, FaFileAlt, FaLaptopCode } from "react-icons/fa";
import { FaDatabase, FaChartLine, FaTools, FaRobot } from "react-icons/fa";
import { servicesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

function DataAnalytics() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(servicesSchemas.dataAnalytics);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <SeoHelmet 
        title="Data Analytics Services - StatixPro"
        description="Transform your business decisions with StatixPro's data analytics services. We turn complex data into actionable insights for your marketing strategy."
        keywords="data analytics, business intelligence, data visualization, marketing analytics, data-driven insights, performance metrics, analytics consulting"
        canonicalUrl="https://statix.pro/data-analytics"
        ogImage="https://statix.pro/data-analytics-og.jpg"
        ogType="website"
      />
<AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
          <h1 className='servicebs_topic'><span className="highlight">Data Analytics & Technical Optimization</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
          <div className="hero-buttons mt-4">
            <a href="https://forms.gle/xXJkPasHLmMXibHi6" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">Get a Free Consultancy</button></a>
            {/* <button className="btn btn-secondary contact-button">Contact us</button> */}
          </div>
        </div>
      </section>

{/* technical expertise section starts here */}
<section className="insights-section container">
      <h2 className="text-center">
      <strong> <span style={{color:"#72797d"}}>Unlock Insights,</span> Drive Excellence:</strong>
      </h2>
      <p className="text-center">
        Learn how to uncover, understand, and improve on the competition – 
        That's what we do at Statix.pro. We offer AI-driven analytics and 
        technical optimization services, helping to derive actionable knowledge 
        from raw information. With advanced tools, we optimize your digital 
        ecosystem for better decisions.
      </p>
      <div className="row mt-5">
        {/* Card 1 */}
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="insight-card">
            <div className="icon-container">
              <FaChartPie />
            </div>
            <h3>Data Analytics</h3>
            <p>
              Harness the power of AI to collect, process, and analyze data,
              providing deep insights into trends, performance, and customer behavior.
            </p>
          </div>
        </div>
        {/* Card 2 */}
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="insight-card">
            <div className="icon-container">
              <FaFileAlt />
            </div>
            <h3>Reporting</h3>
            <p>
              Stay informed with visually engaging reports that simplify complex
              data into actionable intelligence.
            </p>
          </div>
        </div>
        {/* Card 3 */}
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="insight-card">
            <div className="icon-container">
              <FaLaptopCode />
            </div>
            <h3>Technical Audits</h3>
            <p>
              Optimize your website and platforms with thorough audits,
              identifying bottlenecks to boost performance and user experience.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button className="btn btn-primary">Optimize and Thrive</button>
      </div>
    </section>
{/* technical expertise section ends here */}


{/* Technologies we use starts here */}
<section className="technologies-section container">
      <h2 className="text-center">
        <span>Technologies We Utilize</span>
      </h2>
      <div className="row justify-content-center mt-5">
        {/* Card 1: Big Data Tools */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="technology-card">
            <div className="icon-container">
              <FaChartLine />
            </div>
            <h3>Big Data Tools</h3>
            <p>Hadoop, Spark, and Tableau</p>
          </div>
        </div>
        {/* Card 2: Database Management */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="technology-card">
            <div className="icon-container">
              <FaDatabase />
            </div>
            <h3>Database Management</h3>
            <p>SQL, NoSQL, MongoDB</p>
          </div>
        </div>
        {/* Card 3: Performance Analysis */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="technology-card">
            <div className="icon-container">
              <FaTools />
            </div>
            <h3>Performance Analysis</h3>
            <p>Google Analytics, SEMrush, Screaming Frog</p>
          </div>
        </div>
        {/* Card 4: AI-Powered Platforms */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="technology-card">
            <div className="icon-container">
              <FaRobot />
            </div>
            <h3>AI-Powered Platforms</h3>
            <p>TensorFlow, Power BI, Custom Tools</p>
          </div>
        </div>
      </div>
    </section>
{/* Technologies we use ends here */}


{/* CTA starts here */}
<section className="brand-section d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="section-title text-light">Ready to Optimize Your Potential?</h2>
        <p className="section-description">
        With Statix.pro, your data becomes your competitive edge. Our AI-driven solutions empower you to optimize performance, enhance decision-making, and stay ahead in the digital race.
        </p>
        <button className="btn section-button">
        Let’s Connect
        </button>
        <p className="section-footer">
        Contact us today to discover how we can transform your data into a powerhouse of insights and opportunities!
        </p>
      </div>
    </section>
    {/* CTA ends here */}

<Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default DataAnalytics
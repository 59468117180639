import React, { useEffect, useState } from 'react';
import { client } from '../../sanity/client';

function TestSanity() {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function testConnection() {
      try {
        // Simple test query
        const query = '*[_type == "post"]{ _id, title }';
        console.log('Executing query:', query);
        
        const data = await client.fetch(query);
        console.log('Query successful:', data);
        setResult(data);
      } catch (err) {
        console.error('Connection error:', {
          message: err.message,
          details: err
        });
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    testConnection();
  }, []);

  if (loading) return <div>Loading...</div>;
  
  if (error) return (
    <div>
      <h2>Error connecting to Sanity:</h2>
      <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {JSON.stringify(error, null, 2)}
      </pre>
    </div>
  );

  return (
    <div>
      <h2>Connection Successful!</h2>
      <h3>Posts found:</h3>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(result, null, 2)}
      </pre>
    </div>
  );
}

export default TestSanity;
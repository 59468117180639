// client.js
import { createClient } from "@sanity/client";

const config = {
  projectId: "cd0rlkeg",
  dataset: "production",
  apiVersion: "2024-01-01",
  useCdn: false,
  token: "skU83Z6n7P8wYOW3aGPrjA61UKzHma9bxt8TOZdvfCu2eQpotfT2W1rCJEwxYiQ1Ft9Z3ZyJTJSyPoFRvXa4LdQTBuhFZDNPiSrFg4ML6u3qhK635Wf9gIyrtYLy70dh39V6VSeLbgUCE0DRyXfNuLuam3aUcBYJHLcAKaNLIcaA0IGsinho"
};

console.log('Initializing Sanity client with config:', {
  ...config,
  token: config.token ? 'Token present' : 'No token'
});

export const client = createClient(config);

// Test the connection immediately
client.fetch('*[_type == "post"][0]')
  .then(result => console.log('Test query successful:', result))
  .catch(error => console.error('Test query failed:', error));
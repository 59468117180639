import React, { useState } from 'react';
import './faq_section.css';

  const faqs = [
  
    {
      question: "What digital marketing services does StatixPro offer?",
      answer: "StatixPro offers comprehensive digital marketing services including SEO, social media marketing, content creation, AI-powered web development, branding solutions, data analytics, lead generation, and custom software development."
    },
    {
      question: "How does StatixPro use AI in digital marketing?",
      answer: "We leverage AI technology for data analysis, content optimization, personalized marketing strategies, automated campaign management, and predictive analytics to deliver better results for our clients."
    },
    {
      question: "What makes StatixPro different from other digital marketing agencies?",
      answer: "StatixPro stands out through our AI-powered approach, data-driven strategies, comprehensive service offerings, and proven track record of delivering measurable results for our clients."
    },
    {
      question: "How can I get started with StatixPro's services?",
      answer: "You can get started by contacting us through our website, scheduling a free consultation, or filling out our service inquiry form. Our team will assess your needs and create a customized solution."
    },
    {
      question: "What is the importance of data analytics in digital marketing?",
      answer: "Data analytics is crucial in digital marketing as it helps understand customer behavior, optimize campaigns, and make data-driven decisions. It enables businesses to measure performance, identify trends, and adjust strategies for better results."
    },
    {
      question: "How long does it take to see results from digital marketing efforts?",
      answer: "The timeline for results varies depending on factors like your goals, market, and chosen strategies. While some improvements can be seen within weeks, sustainable results typically develop over 3-6 months as we optimize and refine our approach based on data insights."
    },
  ];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);



  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  return (
    <section className="faq-section">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <p className="faq-description">Explore answers to some of the most commonly asked questions about our marketing and branding services. Find out how we can help your business grow and thrive.</p>
      
      <div className="faq-container container">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAnswer(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span className="faq-icon">{activeIndex === index ? "-" : "+"}</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;

import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/navbar'
import AddressBar from '../../components/address_bar/address_bar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import "./privacyPolicies.css"
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { policiesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

function PrivacyPolicies() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(policiesSchemas.privacyPolicy);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <SeoHelmet 
        title="Privacy Policy - StatixPro"
        description="Read StatixPro's privacy policy to understand how we collect, use, and protect your personal information while using our digital marketing services."
        keywords="privacy policy, data protection, personal information, GDPR compliance, data security, privacy terms"
        canonicalUrl="https://statix.pro/privacy-policy"
        ogImage="https://statix.pro/privacy-policy-og.jpg"
        ogType="website"
      />
        <AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
          <h1 className='servicebs_topic'><span className="highlight">Privacy Policy & Terms</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
        </div>
      </section>

      {/* section 2 starts here */}
      <div className="container my-5">
      <div className="policy-header text-center mb-4">
        
        <p className="text-muted">
          Learn about how we protect your privacy and the terms for using our services.
        </p>
      </div>

      {/* Privacy Policy Section */}
      <div className="shadow-sm mb-4" style={{textAlign:"left"}}>
        <div className="card-body">
          <h2 className="card-title text-primary">Privacy Policy</h2>
          <p>
            We are committed to protecting your privacy. This policy explains
            how we collect, use, and safeguard your personal information. By
            using our website, you agree to the terms outlined in this policy.
          </p>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong>Information Collection:</strong> We collect personal
              information such as name, email address, phone number, and payment
              details when you use our services or contact us.
            </li>
            <li className="list-group-item">
              <strong>Data Usage:</strong> We use your data to provide and
              improve our services, communicate with you, and send marketing
              materials with your consent.
            </li>
            <li className="list-group-item">
              <strong>Data Security:</strong> We employ industry-standard
              measures to protect your information from unauthorized access,
              alteration, or destruction.
            </li>
            <li className="list-group-item">
              <strong>Cookies:</strong> Our website uses cookies to enhance your
              experience. You can choose to disable them, but it may affect the
              functionality of our site.
            </li>
            <li className="list-group-item">
              <strong>Third-Party Disclosure:</strong> We do not sell or rent
              your personal information to third parties. However, we may share
              it with trusted service providers for specific functions, such as
              payment processing.
            </li>
          </ul>
        </div>
      </div>

      {/* Terms & Conditions Section */}
      <div className="shadow-sm" style={{textAlign:"left"}}>
        <div className="card-body">
          <h2 className="card-title text-primary">Terms & Conditions</h2>
          <p>
            By accessing our website, you agree to the following terms and
            conditions:
          </p>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong>Service Usage:</strong> You must be at least 18 years old
              to use our services.
            </li>
            <li className="list-group-item">
              <strong>Account Responsibility:</strong> You are responsible for
              maintaining the confidentiality of your account details.
            </li>
            <li className="list-group-item">
              <strong>Modifications:</strong> We reserve the right to change our
              terms and privacy policy at any time. You will be notified of any
              significant changes.
            </li>
            <li className="list-group-item">
              <strong>Limitation of Liability:</strong> We are not liable for
              any direct, indirect, or consequential damages arising from the
              use of our website or services.
            </li>
          </ul>
        </div>
      </div>
    </div>
      {/* section 2 ends here */}

      
      <Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>

    </div>
  )
}

export default PrivacyPolicies